.slick-initialized .slick-slide {
    display: block;
    padding: 20px;
}

a {
    text-decoration: none;

}

.uar-wrapper {
    width: 100%;
}

.uar-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100vh;
}

.uar-section-box {
    position: relative;
    display: flex;
    padding: 0 0px 0 0px;
    margin: 40px 0;
    min-height: 450px;
    height: auto;
}

.uar-inner-section-box {
    position: relative;
    display: flex;
    padding: 0;
    height: auto;
    padding: 40px 20px;
    box-shadow: 2px 5px 1px 0px rgba(77, 70, 121, 0.15);
    border-radius: 8px;
    transition: 0.3s;
    border: 1px solid #f6f5ff;
    border-bottom: 10px solid #f6f5ff;
}

.uar-inner-section-box:hover {
    border: 1px solid rgb(71, 23, 108);
    border-bottom: 10px solid rgb(71, 23, 108);
    background-color: #fff;
}

.uar-section-content {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    height: auto;
}

.uar-section-content.uar-grid-col-01 {
    grid-column-start: 1;
    grid-column-end: 1;
    -ms-grid-column: 1;
}

.uar-section-block-title {
    color: #ffffff;
    font-size: 24px;
    text-transform: uppercase;
    font-variation-settings: "wght" 600, "wdth" 63;
    letter-spacing: -0.04em;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    background: var(--Linear, linear-gradient(149deg, #00112d 5.29%, #230048 39.26%, #040020 73.14%, #070031 108.07%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mgh {
    overflow: hidden;

}

.uar-section-block-body {
    padding: 0 0px 0 0;
    width: 100%;
    min-height: 100px;
}

.uar-section-box p {
    margin-top: 0;
    margin-bottom: 0.8em;
}

.uar-icon {
    background-color: rgb(71, 23, 108);
    min-width: 125px;
    height: 135px;
    border-radius: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 84px;
    color: #fff;
    box-shadow: 3px 4px 2px 0px rgba(47, 40, 87, 0.15);
    transition: 0.3s;
    width: max-content;
}

.uar-section-box a p {
    color: black;
}

.arrow-icon a {
    text-decoration: none;
}
.premium__slide {
    height: 550px !important; /* Set a fixed height for each card */
}
@media screen and (max-width:600px) {
    .premium__slide {
        height: 650px !important; /* Set a fixed height for each card */
    } 
}