@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: 'Montserrat' !important;
}
.main-color-bg{
    background-color:#4e286e;
}
.dark-color-bg{
    background-color:#00112d !important;
}   
.main-color{    
    color: #4e286e;
}
.nav-logo{
    width: 125px;
}
@media screen and (max-width:768px) {
    .nav-logo{
        width: 100px;
    }
}
.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Limit the number of lines to display */
}
.line-clamp-5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5; /* Limit the number of lines to display */
}
