
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html{

    margin: 0 !important;
    padding: 0 !important
}

.main-header{
    height: 85vh;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    position: relative;
}
.container.main-header{
    color: white;
}

#ser-text{
    color: white;
    font-weight: 400;
    font-size: 2rem;
    font-family: Montserrat;
}
#ser-text1{
    /* font-size: 3rem; */
    line-height: 60px;
    font-family: 'Montserrat';
}
.pp{
    color:rgb(120, 120, 120);
}


#des-text{
    font-family: 'Montserrat';
    font-weight: 500;
    /* text-align: justify; */
}

#hero{
    height:70vh;
    padding-top: 10%;
    background-color: #6B21A8;
}



@media only screen and (max-width: 720px) {
    #hero{
        height: 100vh;
        font-size: larger;
    }
  }
  @media only screen and (max-width: 720px) and (min-width:420px) {
    #ser-text1{
      color: white !important;
      font-size: xx-large;
      line-height: 40px ;
    }
  }
  @media only screen and (max-width: 1500px) and (min-width:420px) {
   .ss{
    padding-left: 20px;
   }
  }

  @media only screen and (max-width: 1650px) and (min-width:1025px) {
    .ss{
     padding-left: 10px;
    }
   }
  @media only screen and (max-width: 426px) {
    #hero{
        height: 100vh;
        font-size: large;
        background-color: #6B21A8;
        color: white !important;
    }
    #ser-text1{
      color: white !important;
      font-size: xx-large;
      line-height: 40px ;
    }
    #ser-text{
      
      font-size: large;
      
    }
    .bott h5 {
    text-align: center;
    padding-top: 20px;
  }
  .b1{
    /* padding-left: 40px; */
    text-align: center;
    padding-bottom: 30px;
    
  }
  #hero{
    height:70%;
    padding-top: 10%;
}
.ss{
  padding-left: 10px;
}
  }



  .oph:hover {
    background-color: #e3e3e333;
    color: #13294b;

  }
  .oph{
    height: 350px;
    /* text-align: center; */
  }

  .vl {
    border-left: 2px solid rgba(182, 182, 182, 0.578);
    height: 20px;
    margin-left: 2px;
    padding: 6px 0px 6px 0px !important;
    margin: 15px 0px 15px 0px;
  }

  .main{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
.bott{
    background-color: #450d73;
}

.bottt{
  background-color: white;
  
}
/* .arrowp{
    display: none;
} */

.arrowp {
    display: none; /* Hide the image by default */
  }
  
  .arrowp.show {
    display: block; /* Show the image when the show class is present */
  }
  .searchbuttons{
margin-left: 500px !important;
background-color: #450D73 !important;

  }


  .searchbuttons:hover{
    
    background-color: black !important;
    
      }


      .heading-sub{
        font-family: 'Montserrat';
      }



      /* mantasha css */
      .main-header{
        height: 85vh;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        position: relative;
    }
    .container.main-header{
        color: white;
    }
.ss{
  justify-content: center;
  align-items: center;
  /* display: flex; */
}